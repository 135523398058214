<template>

    <div class="title">InterAct Speaking Center | Terms and Conditions</div>
    
    <div class="subtitle">General Terms</div>
    
    <div class="TCcontainer">
    
    
        <ol type="I">
            <li>
                <div class="subtitle">Eligibility</div>
                <ol type="i">
                    <li>
                        All users must be at least 18 years old to use the services of InterAct Speaking Center. Minors may only use the services under the supervision of a parent or legal guardian, who agrees to these terms on their behalf.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Account Responsibilities</div>
                <ol type="i">
                    <li>
                        Users must provide accurate and complete information during registration and maintain the security of their account credentials. They are responsible for all activities occurring under their accounts.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">User Content</div>
                <ol type="i">
                    <li>
                        By posting or sharing content on the platform, users grant InterAct a global, irrevocable license to use, reproduce, modify, and distribute the content for business and promotional purposes. All content must comply InterAct media guidelines.
                    </li>
                    <li>
                        Users are responsible for the accuracy, legality, and appropriateness of their content. InterAct does not endorse any content posted by users.
                    </li>
                    <li>
                        If upon revision content is deemed harmful or not aligned with InterAct media guidelines then user will be subject to increased scrutiny and deactivation of account if deemed necessary. 
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Modifications to Terms</div>
                <ol type="i">
                    <li>
                        InterAct reserves the right to modify these terms and conditions at any time. Users will be notified of any significant changes. Continued use of the platform constitutes acceptance of the new terms.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Dispute Resolution</div>
                <ol type="i">
                    <li>
                        The parties agree that any disputes, claims, or controversies arising out of or relating to this contract shall not be resolved through court litigation or binding arbitration. Instead, the parties will attempt to resolve any dispute through good-faith negotiation or mediation.
                    </li>
                    <li>
                        In the event of a dispute, the parties agree to first attempt to resolve the matter through direct negotiation between authorized representatives. If the dispute cannot be resolved through negotiation within [30 days], the parties will submit the dispute to mediation with a mutually agreed-upon mediator. The cost of mediation shall be shared equally by both parties
                    </li>
    
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Termination of Service</div>
                <ol type="i">
                    <li>
                        InterAct reserves the right to terminate any user account or restrict access for any violation of the terms or misuse of the platform.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Data Privacy</div>
                <ol type="i">
                    <li>
                        InterAct collects, processes, and stores personal data in accordance with applicable data protection laws, including the GDPR. All users must comply with relevant privacy laws.
                    </li>
                </ol>
            </li>
        
        </ol>
    
    </div>
    
    <div class="subtitle">Tutor Terms</div>
    <div class="TCcontainer">
        <ol type="I">
            <li>
                <div class="subtitle">Independent Contractor Status</div>
                <ol type="i">
                    <li>
                        Tutors are classified as independent contractors, not employees. Tutors are responsible for their own taxes, insurance, and any expenses incurred while providing services. InterAct does not guarantee any minimum number of Students, income or hours.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Payment Terms</div>
                <ol type="i">
                    <li>
                        InterAct offers a variable payment scale that depends on Tutor's ongoing proficiency, monthly Student feedback and InterAct Administrator or Trainer feedback during initial and recurrent classes or orientations. Reference the following table for each level of requirements. 
                        <div class="rate-requirements">
                            <table>
                                <thead>
                                    <th>Rate</th>
                                    <th>InterAct Platform</th>
                                    <th>Whatsapp Group</th>
                                    <th>Videocall Software</th>
                                    <th>Whiteboard/Presentation Software</th>
                                    <th>Admin/Student Feedback</th>
                                    <th>Cancellations</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            150mxn/hour
                                        </td>
                                        <td>
                                            <ol type="a">
                                                <li>
                                                    Tutor can confirm, cancel, reschedule classes proficiently.
                                                </li>
                                                <li>
                                                    If a change of status is requested by Student or Tutor, Tutor must update the status on platform as well before day ends.
                                                </li>
                                                <li>
                                                    Tutor must update their availability on their own.
                                                </li>
                                                <li>
                                                    Tutor must request a group schedule change ticket on their own.
                                                </li>
                                                <li>
                                                    Tutor can view their hoursheet.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol type="a">
                                                <li>
                                                    Tutor must send confirmation of class before class. Students usually expect 1 hour confirmations before class time minimum.
                                                </li>
                                                <li>
                                                    Tutor sends a clear confirmation,cancellation or reschedule message notifying the Students about the status or change of status.
                                                </li>
                                                <li>
                                                    If Students have not yet connected, Tutor must notify their Students that class has started in the first 5 or 10 minutes through group chat.
                                                </li>
                                                <li>
                                                    If communication happens outside of the group chat, teacher must still state the status of the class on the group chat, otherwise payment for this class may be witheld until the status can be confirmed by Administration or management.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol type="a">
                                                <li>
                                                    Tutor must be proficient with all major video call technologies: Zoom, Google Meet, Whatsapp VideoCall, Microsoft Teams.
                                                </li>
                                                <li>
                                                    Tutor must be able to create video call links in advanced.
                                                </li>
                                                <li>
                                                    Tutor must be able to switch between any of the previously mentioned software in order to teach their classes in case of malfunction.
                                                </li>
                                                <li>
                                                    Tutor must be able to share screen and audio proficiently.
                                                </li>
                                                <li>
                                                    InterAct provides it's own free internal video call solution but does not impose it on Tutors or Students; meaining Tutors must pay for their own account upgrades (Zoom, Google Meet, Microsoft Teams) if they want to remove video call restrictions such as 1-hour calls or limits on number of participants.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol type="a">
                                                <li>
                                                    Tutor must be proficient with all major whiteboard/presentation software: Google Slides, Microsoft Powerpoint or any other traditional presentation software.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            *None needed but it can affect Tutor's class schedule if Students or Administration provide feedback.
                                        </td>
                                        <td>
                                            <ol>
                                                <li>
                                                    Tutor must have attended 85% of scheduled classes (except if class had been rescheduled or cancelled by Student) in the last 30 days minimum.
                                                </li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            170.- mxn
                                        </td>
                                        <td>*same as previous level</td>
                                        <td>*same as previous level</td>
                                        <td>*same as previous level</td>
                                        <td>Tutor uses exclusively whiteboard type software and can manage sharing multimedia content.</td>
                                        <td>
                                            <ol>
                                                <li>
                                                    Tutor must have taught at least 4 groups
                                                </li>
                                                <li>
                                                    Student Feedback must be positive for all groups teacher had in the last 30 days minimum.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            Teacher must have attended 90% of classes (except those cancelled or rescheduled by Students) in the last 30 days minimum.
                                        </td>
    
                                    </tr>
                                    <tr>
                                        <td>
                                            200.- mxn
                                        </td>
                                        <td>*same as previous level</td>
                                        <td>*same as previous level</td>
                                        <td>*same as previous level</td>
                                        <td>*same as previous level</td>
                                        <td>
                                            <ol>
                                                <li>
                                                    Tutor must have taught at least 6 groups in the last 30 days.
                                                </li>
                                                <li>
                                                    Student Feedback must be positive for all groups teacher had in the last 30 days minimum.
                                                </li>
                                                <li>
                                                    Admin Feedback must be positive.
                                                </li>
                                            </ol>
                                        </td>
                                        <td>
                                            Teacher must have attended 100% of scheduled classes (except those cancelled or rescheduled by Students) in the last 30 days minimum.
                                        </td>
    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
    
                    </li>
                    <li>
                        Failure to align to these guidelines can result in removal of groups from Tutor's class schedule.
                    </li>
                    <li>
                        Tutors are paid on days 5th and 20th of each month, provided they meet a minimum payment requirements (e.g., hoursheet validation). If this minimum is not met, payments are subject to delays and fee incursion. 
                    </li>
                    <li>
                        All payments are processed through third-party services. Any processing fees associated with payments to tutors will be borne by the tutor. It is the tutor's responsibility to cover these fees from their payment, and such fees will not be absorbed or reimbursed by the organization.
                    </li>
                    <li>
                        Payout methods accepted.
                        <ol type="a">
                            <li>Wise</li>
                            <li>Payoneer</li>
                            <li>Paypal</li>
                            <li>Crypto (BTC, ETH, XRP, other if applicable)</li>
                        </ol>
                    </li>
                    <li>
                        Minimum payout amount must be over 500.- mxn, otherwise the pending amount will be added to next payout.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Promotional Materials</div>
                <ol type="i">
                    <li>
                        Tutors consent to the use of their names, images, voices, and other likenesses by InterAct for advertising and promotional purposes only and without additional compensation. InterAct retains all rights to any promotional material created by contracted marketing or social media experts using such likenesses.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Non-Exclusivity</div>
                <ol type="i">
                    <li>
                        Tutors may offer their services on other platforms and work with other clients. InterAct also retains the right to engage with multiple service providers and promote diverse tutoring services outside the scope of Tutor's abilities or skill set.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Data Privacy for Tutors</div>
                <ol type="i">
                    <li>
                        Tutors must protect all Student data they access and comply with all relevant privacy laws, including not sharing data for purposes beyond providing tutoring services.
                    </li>
                </ol>
            </li>
            <li>
                <div class="subtitle">Communications Consent</div>
                <ol type="i">
                    <li>
                        By using the InterAct website, users consent to receive notifications regarding their classes and payments through SMS, WhatsApp, and email.
                    </li>
                </ol>
            </li>
        </ol>
    
    </div>
    
    <div class="subtitle">Student Terms</div>
    
    <div class="TCcontainer">
    
        <ol type="I">
            <li>
                <div class="subtitle">Payment and Refund Policy</div>
                <ol type="i">
                    <li>
                        Students must pay in advance for lessons or subscriptions unless otherwise established in previous negotiations. Refunds may be requested within a specified period (e.g., 28 days) if no lessons or credits have been used.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Data Privacy for Students</div>
                <ol type="i">
                    <li>
                        InterAct collects and processes Student data solely for the purposes of providing educational services, and in accordance with its privacy policy. Student data will not be shared with third parties without consent, except as required by law.
                    </li>
                </ol>
            </li>
    
            <li>
                <div class="subtitle">Communications Consent</div>
                <ol type="i">
                    <li>
                        By using the InterAct website, users consent to receive notifications regarding their classes and payments through SMS, WhatsApp, and email.
                    </li>
                </ol>
            </li>
    
            <!-- <li>
                <div class="subtitle"></div>
                <ol type="i">
                    <li>
    
                    </li>
                </ol>
            </li> -->
    
        </ol>
    
        By using InterAct Speaking Center, all users (Students and tutors) agree to these terms and conditions. Failure to adhere to these terms may result in the termination of access to the platform.
    
    </div>
    
    </template>
    
    
    
    <script setup>
    
    
    
    </script>
    
    
    <style scoped>
    
    .TCcontainer{
        width: 100%;
        max-width: 100vw;
        padding: 2rem 0 2rem 2rem;
    
    }
    
    li{
        width: 100%;
        text-align: justify;
        margin-top: 10px;
    }
    
    
    .rate-requirements{
        max-width: 100vw;
        width: 100%;
        overflow: auto;
    }
    
    table{
        overflow: scroll;
        width: min-content;
        max-width: calc(100vw - 2rem);
    }
    
    table li{
    
        padding: 10px;
    }
    
    td{
        min-width: 300px;
    }
    
    </style>
    