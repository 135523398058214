<template>
    <div @click.stop="toggle_collection()" class='title'>
        Collection Report
    </div>

    
    
    <div class="collections" :class="{show:collection.show_collection, hide:!collection.show_collection}">
        <div class="search_container">
    
            <input class="search_box" v-model="collection.search" @keypress.enter.stop="add_to_filter()" type="text" placeholder="Search">
            <div class="filter_container">
                <div @click.stop="remove_filter(filter)" class="filter" v-for="filter in collection.filters">{{ filter }}</div>
            </div>
    
        </div>
        
        <table>
            <thead>
                <th>Group</th>
                <th>Student</th>
                <th>Payments</th>
                <!-- <th>Vacation Status</th> -->
                <th>Comments</th>
                <!-- <th>Days since last class</th> -->
                
            </thead>
            <tbody>
                <tr :class="{strong_urgent:student.collection_status>=3,urgent:student.collection_status==2,weak_urgent:student.collection_status==1,ok:student.collection_status==0,null:student.collection_status==-9,inactive:student.collection_status==-10, end:student.payment_method.includes('@end')}" v-for="student in collection.filtered_students">
                    <td>
                        <div class="group-container">
                            <template v-if="student.groups">
                                <template v-for="group in student.groups">
                                    <table>
                                        <tr>
                                            <template v-if="group.instructor">
                                                <template v-for="instructor in group.instructor">
                                                    <button @click.stop="show_edit_window(instructor)">
                                                        {{ instructor.ui }}
                                                    </button>
                                                </template>
                                            </template>
                                        </tr>
                                        <tr>
                                            <button @click.stop="show_edit_window(group)">
                                                Group: {{ group.id }}
                                                <hr>
                                                {{ group.schedule }}
                                            </button>
                                        </tr>
                                    </table>    
                                </template>
                            </template>
                        </div>
                    </td>

                    <td>
                        <div class="student-container">
                            <button class="subtitle" @click.stop="show_edit_window(student)">
                                {{ student.user.first_name }} {{ student.user.last_name }} 
                            </button>
                            <button @click.stop="send_to_student_dashboard(student.user.id)">DASHBOARD</button>
                            <div> {{ student.organization }}</div>
                            <div>{{ student.payment_method }}</div>
                            <div>{{ student.payment_plan }}</div>
                            <div>{{ student.monthly_rate }}</div>
                            <div>{{ student.level }}</div>
                            <button @click.stop="open_whatsapp(student.user.whatsapp)">Student Whatsapp</button>
                        </div>
                    </td>
                    <td @click.stop="show_payments_window(student)">
                        <button>
                            <template v-if="student.payments">
                                <table>
                                    <template v-for="payment in student.payments">
                                        <tr class="payment" :class="{paid:payment.paid}">
                                            {{ payment.ui }} 
                                        </tr>
                                    </template>
            
                                </table>
        
                            </template>
                        </button>
                    </td>
    

                    <!-- <td>Vacation status here</td> -->


                    <!-- <td v-if="student.show_comments">
                        <textarea @keydown.enter="patch_comments(student,'comments')" v-model="student.comments"> 
                        </textarea>
                    </td> 
                    <td @click.stop="show_comments(student)" v-else>
                        {{ student.comments }}
                    </td> -->

                    <!-- @input="update_inner_html($event,student)"  -->
                    <td>
                        <div class="student_comments" @click.stop="show_comments(student)" @blur.stop="patch_comments($event,student,'comments')" @keydown.enter.stop="patch_comments($event,student,'comments')" :contenteditable="student.show_comments">
                            {{ student.comments }}
                        </div>
                    </td> 



                    <!-- <td>Days since last class</td> -->
    
                </tr>
            </tbody>
        </table>

    </div>


    <Payments/>
    <Edit/>


</template>

<script setup>



import axios from 'axios'
import {reactive, onBeforeMount, provide} from 'vue'
import Payments from '@/components/Payments.vue'
import Edit from '@/components/Edit.vue'


let collection=reactive({
    students:[],
    filtered_students:[],
    filters:[],
    search:"",
    show_collection:false
})
let data=reactive({
    payments_student:{},
    student_payments:[],
})

provide('data',data)
onBeforeMount(async ()=>{
    
    let response=await axios.post('/collection/get_collection/')
    collection.students=response.data
    collection.filtered_students=collection.students

    console.log(collection)
})


function toggle_collection(){
    collection.show_collection=!collection.show_collection
}




async function show_payments_window(student){
    let payments=await axios.post('/payments/get_payments/',{student_id:student.id})
    let instructor_response=await axios.post('/payments/get_plain_instructors/')
    let student_response=await axios.post('/payments/get_plain_students/',{student_id:student.id})
    let group_response=await axios.post('/payments/get_plain_groups/',{student_id:student.id})


    data.student_payments=[]


    data.emergency_group={}

    data.payment_groups=group_response.data
    data.payment_instructors=instructor_response.data
    data.payment_students=student_response.data
    data.student_payments=payments.data

    data.payments_student=student
    data.show_payments_window=true
    data.hide_payments_window=false

    console.log(data.payment_groups)

}



async function show_edit_window(object){
    
    let response=await axios.post('/admin/get_object/',{object:object})
    // Object.assign(object,response.data)
    object=response.data
    data.edit_object=object
    data.show_edit_window=true
    data.hide_edit_window=false

}

function open_whatsapp(whatsapp_link){
    var link = window.open('https://'+whatsapp_link, '_blank');
    link.focus();

}


function send_to_student_dashboard(student_id){
        window.open('/student-dashboard?user_id='+student_id, '_blank');

    }

function patch_comments(e,object,key){
    object[key]=e.target.innerText
    let value=object[key]
    let response=axios.patch('/admin/patch/',{object:object,key:'comments',value:value})
    object.show_comments=false
    object.comments=e.target.innerText

}

function show_comments(student){
    student.show_comments=true
}


function add_to_filter(){

    let query=collection.search
    collection.search=""

    let invalid_filters=['  ','   ','    ','\\n']

    invalid_filters.forEach(invalid_filter=>{
        query=query.replace(invalid_filter,'')
    })

    if (!collection.filters.includes(query)){
        collection.filters.push(query)
    }

    update_filtered_students()

}


function update_filtered_students(){
    console.log('UPDATING FILTERS')
    console.log('FILTERS',collection.filters)
    console.log('STUDENTS',collection.filtered_students)

    if (collection.filters.length==0){
        console.log('FILTERS ARE EMPTIED')
        collection.filtered_students=collection.students
        return
    }

    collection.filtered_students=[]

    collection.filters.forEach(filter=>{

        collection.students.forEach(student=>{
            let found=false
            console.log(student)
            for(let key in student){

                if (key=='user'){
                    for (let userkey in student[key]){
                        if (typeof student[key][userkey] === 'string' && student[key][userkey].toLowerCase().includes(filter.toLowerCase())){
                            found=true
                            break
                        }
                    }
                }

                if (typeof student[key] === 'string' && student[key].toLowerCase().includes(filter.toLowerCase())){
                    found=true
                }
                if (found==true){
                    collection.filtered_students.push(student)
                    break
                }
                
            }
        })

    })

    if (collection.filtered_students==[]){
        collection.filtered_students=collection.students
    }


    console.log('FILTERS',collection.filters)
    console.log('STUDENTS',collection.filtered_students)

}

function remove_filter(filter){
    collection.filters=collection.filters.filter(in_filter=>in_filter!=filter)
    update_filtered_students()
}




</script>



<style scoped>







    .collections{
        width: 0;
        height: 0;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        overflow:auto;
    }

    .search_container{
        width: 100%;
        max-width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    .search_box{
        border-radius: 5px;
        border:var(--border);
        min-height: 20px;
        width: 200px;

    }

    .filter_container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:12px;
    }


    .filter{
        color: var(--black);
        background: var(--lightbrown);
        border: var(--border);
        cursor:pointer;
    }
    

    .group-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;
    }

    .student-container{
        display: flex;
        min-width: max-content;
        width: 100%;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;
    }

    .student_dashboard{
        color:var(--white);
        
    }

    .student_comments{
        width: 90%;
        max-width: 300px;
        min-height: 100px;
        height: auto;
        border: var(--border);
        
    }


    .strong_urgent{
        background: var(--darkred);
    }


    .urgent{
        background: var(--red);
    }
    .weak_urgent{
        background: var(--darkyellow);
    }
    .ok{
        background: var(--darkgreen);
    }

    .null{
        background:var(--white);
        color:var(--black);
    }

    .inactive{
        background: var(--gray);
        color: var(--white);
    }

    .payment{
        background: var(--red);
        color:var(--white);
    }


    .paid{
        background: var(--green);
        color:var(--white);
    }

    .end{
        border: var(--end-border);
    }

    .title{
        cursor: pointer;
    }


</style>