<template>
    <div class="message-manager">
        <div class="title">
            Initial Message Maker
        </div>
        <input @input.stop="search_users" class="user-search-input" v-model="users.searchpattern">
        <div class="search-results">
            <template v-if="users.users">
                <div class="result" @click.stop="create_message(user)" v-for="user in users.users">
                    {{ user.ui }}
                </div>
            
            </template>
        </div>
        <textarea class="message" v-model="users.current_message">

        </textarea>
    </div>

</template>



<script setup>

    import axios from 'axios'

    import {reactive} from 'vue'

    let users=reactive({
        searchpattern:'',
        users:null,
    })

    async function search_users(){

        let response=await axios.post('/dashboard/search_users/',{searchpattern:users.searchpattern})
        users.users=response.data
        console.log(users)
        Array.from(users.users).forEach(user=>{

            let password=`${user.first_name.slice(0,2).toLowerCase()}${user.last_name.slice(0,2).toLowerCase()}2023`

            user.message=`Hola ${user.first_name},

Este es tu usuario y contraseña:

usuario:
${user.username}
contraseña:
${password}

Puedes ingresar a las clases por medio del link que el teacher enviará al grupo de whatsapp; o bien, puedes ingresar desde el portal de alumno:

https://interactspeakingcenter.com/login/

Gracias!            
            `
        })

    }

    function create_message(user){
        users.current_message=user.message
    }

</script>



<style scoped>

    .title{
        font-size: xxx-large;

    }

    .message-manager{
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        width: 100vw;
        gap:1rem;

    }
    .user-search-input{
        border-radius: 1rem;
        height: 3rem;
        font-size: x-large;
    }

    .search-results{
        display: grid;
        gap: 1rem;
        /* grid-template-columns: repeat(auto-fill,10rem); */

    }

    .result{
        border-radius: 1rem;
        border: 5px solid var(--green1);
        background:var(--blue1);
        color: var(--white);
        font-size: xx-large;

    }

    .message{
        background: var(--black);
        color:var(--white);
        border-radius: 1rem;
        width:min(100vw,20rem);
        height: 20rem;
        padding:1rem;
        font-size: large;
    }


</style>