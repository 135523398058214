<template lang="html">

  <!-- <router-link to="/bad-attendances">
    <Card ref="bad_attendances"/>  
  </router-link> -->

    
    
    
  <div class="cards">
    <template v-for="card in cards_data">
      <div class="card">
        <span class='total'>{{ card.total }}</span>
        <span class="title">{{card.title}}</span>
      </div>
    </template>
  </div>

</template>

<script setup>
  import axios from 'axios'
  import {ref,reactive,inject} from 'vue'

  let cards_data=reactive({})
  cards_data=inject('cards_data')

















</script>

<style lang="css" scoped>

  .cards{
    display: grid;
    grid-template-columns: repeat(auto-fill,20rem);
    gap: 1rem;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .card{
    display: flex;
    flex-direction: column;
    background: var(--red3);
    color:var(--white);
    height: 10rem;
    width: 15rem;
    align-items:end;
    justify-content:center;
    border-radius: 1rem;
  }

  .total{
    font-weight: bold;
    font-size: xxx-large;
  }

  .title{
    font-size: xx-large;
  }

  .student{
    background: var(--blue1);
  }
  .instructor{
    background: var(--red1);
  }
  .group{
    background:var(--yellow1);
  }
















</style>
